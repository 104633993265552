<template>
   <div class="subdistributorManage-index-wrapper wrapper-scroll-view">
       <div class="cover flex-box-column">
           <div class="hd">
                <van-nav-bar left-arrow title="分销商管理"  @click-left="back"></van-nav-bar>
           </div>
           <div class="bd">
               <div class="channel-total">
                   <div class="channel-data-desc">
                       <div class="subdistributor-total item" @click="golink({path:'/subdistributor/manage/list'})">
                           <p class="number">{{num}}</p>
                           <p class="label">我的分销商</p>
                       </div>
                       <div class="subdistributor-audit-total item van-hairline--left" @click="golink({path:'/subdistributor/manage/audit/list'})">
                           <p class="number">{{checkNum}}</p>
                           <p class="label">待审核的分销商</p>
                       </div>
                   </div>
               </div>
               <section class="subdistributorManage-index-section">
                   <h2 class="subdistributorManage-index-section__title">分销商注册管理</h2>
                   <div class="subdistributorManage-index-section__content">
                        <!-- 导航列表 -->
                        <div class="nav-list" v-if="$has('record-distributor-audit')">
                            <van-grid :border="false">
                                <van-grid-item v-for="item in registerMenuList" :key="item.path" :icon="item.icon"  :text="item.text"  :to="{path:item.path}"/>
                            </van-grid>
                        </div>
                   </div>
               </section>  
               <section class="subdistributorManage-index-section">
                   <h2 class="subdistributorManage-index-section__title">分销商管理</h2>
                   <div class="subdistributorManage-index-section__content">
                        <!-- 导航列表 -->
                        <div class="nav-list" v-if="$has('record-distributor-list')">
                            <van-grid :border="false">
                                <van-grid-item v-for="item in detailMenuList" :key="item.path" :icon="item.icon"  :text="item.text"  :to="{path:item.path}"/>
                            </van-grid>
                        </div>
                   </div>
               </section>
           </div>
       </div>
   </div>
</template>

<script>
import backMixins from "@/mixins/back";
import api from "@/api";

export default {

    mixins:[backMixins],

    data(){

        return{

            //分销商注册管理路由列表

            registerMenuList:[

                {
                    icon:'system-distributor-audit',

                    path:'/subdistributor/manage/audit/list',

                    text:'审核列表'

                },
                
                
                
                // {

                //     icon:'system-apply-list',

                //     path:'/subdistributor/manage/apply/list',

                //     text:'申请列表'


                // },{
                //     icon:'system-add-distributor',

                //     path:'/subdistributor/manage/add',

                //     text:'新增分销商'

                // },{

                //     icon:'system-add-staff',

                //     path:'/subdistributor/manage/staff/add',

                //     text:'新增员工'

                // }

            ],

            //分销商管理

            detailMenuList:[

                {
                    icon:'orders-o',

                    path:'/subdistributor/manage/list',

                    text:'分销商列表'

                },

            ],

            totalData:{}

        }

    },

    computed:{

        num(){

            return this.totalData?.num || 0;

        },

        checkNum(){

            return this.totalData?.checkNum || 0;

        }

    },


    methods:{

        getInitData(){

            this.$toast.loading({

                duration:0,

                overlay:true,

                message:'正在加载...'

            });


            api.getSubdistributorNum({

                success:(res)=>{

                    if(res.type == 200){

                        this.totalData = res.data;

                    }else{

                        this.$toast.fail(res.msg || '分销商数据获取失败');

                    }

                },

                error:(err)=>{

                    console.log(err);
                    
                    this.$toast.fail('分销商数据获取失败,数据获取异常');
                    
                },

                complete:()=> {

                    this.$toast.clear();
                    
                }

            })

        },

        golink(path){

            this.$router.push(path);
            
        }

    },

    created(){

        this.$nextTick(()=>{

            this.getInitData();

        })

    }

}
</script>

<style lang="less">
.subdistributorManage-index-wrapper{

    .bd{

        background: #f6f6f6;

    }

    .channel-total{

        display: flex;
        
        flex-direction: column;

        align-items: center;

        background: #fff;

        .channel-desc{

            padding: 10px 0;

            .avatar{

                width: 60px;

                height:60px;

            }

            .name{

                padding-top:5px;

                font-size:14px;

                text-align: center;

            }

        }

        .channel-data-desc{

            display: flex;

            width: 100%;
            
            .item{

                flex:1;

                height:80px;

                display: flex;

                flex-direction:column;
                
                justify-content: center;

                align-items: center;

                .number{

                    font-size:24px;

                }

                .label{

                    font-size:14px;

                    line-height:30px;

                }

                &:first-child .number{

                    color:#3bc4ff;

                }
                
                &:nth-child(2) .number{

                    color:#03e403

                }

            }

        }

    }

    .subdistributorManage-index-section{

        .subdistributorManage-index-section__title{

            padding: 10px 26px;

            color:#999999;

            font-size:14px;

        }

        .subdistributorManage-index-section__content{

            background: #fff;

            box-shadow:0 2px 3px rgba(0,0,0,.05);

            .nav-list{

            margin-bottom:10px;

            padding: 16px 10px 6px;

            .van-grid-item{

                margin-bottom:10px;

                .van-grid-item__content{

                    padding: 0;

                }

                &.big{

                    .van-grid-item__icon{

                        font-size:26px;

                    }

                }

                .van-grid-item__icon{

                    background: #fff;

                    color:#62b3ff;

                    display: block;

                    width: 45px;

                    height:45px;

                    border-radius:50%;

                    overflow: hidden;

                    box-shadow: 0 2px 5px rgba(0,0,0,.1);

                    text-align: center;

                    line-height:45px;

                    font-size:24px;

                }

                .van-grid-item__text{

                    color:#a09d9d;

                    font-size:13px;

                }


            }

        }

        }

       

    }

}
</style>
